
import axios from './api';

const apiKey:string = "bbe7-d64d2da66c3a";

interface ApiService {
    get<T>(url: string, params?: object): Promise<T>;
    post<T>(url: string, data?: object): Promise<T>;
    // ... add put, delete, etc. if needed
}

const apiService: ApiService = {
    async get<T>(url:string, params = {}) {
        const response = await axios.get<T>(url.replace("#apikey#","bbe7-d64d2da66c3a"), { params });
        return response.data;
    },

    async post<T>(url:string, data = {}) {
        const response = await axios.post<T>(url, data);
        return response.data;
    },

    // ... implement put, delete, etc. 
};

export default apiService;

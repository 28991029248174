import React from "react";
import { useRace } from "../../../../services/RaceProvider";

interface Props{
  relay?: boolean;
}

const ResultTableHeaderCommon = ({relay}:Props) => {
  const {raceid} = useRace();
  return (
    <>
      <th className="result-table-rank">Pl.</th>
      <th className="result-table-bib">Strn</th>
      <th className="result-table-name">Name</th>
      {relay ? (<th className="result-table-birth">Birth</th>) : null}
      {relay ? (<th className="result-table-club">Team</th>) : null}
      <th className="result-table-nation">Team</th>
    </>
  );
};
export default ResultTableHeaderCommon;
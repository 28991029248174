import React, { useEffect, useState } from 'react'
import { RaceAthleticsDTO } from '../../interfaces/RaceAthleticsDTO';
import apiService from '../../services/apiService';
import { Race } from './Race';
import './racestyle.css';

const Races = () => {
    const [races, setRaces] = useState<RaceAthleticsDTO[] | []>([]);
    useEffect(() => {    
        const fetchData = async () => {
          try {
            const result = await apiService.get<RaceAthleticsDTO[]>(
              "/GetRaces"
            );
            // console.log(JSON.stringify(result));
            const sortedResult = result.sort((a, b) => new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime());
        
            setRaces(sortedResult);
    
            document.title = 'CS-Elite: Choose race';
    
          } catch (error) {
            console.error("Error fetching data", error);
          }
        };
        fetchData();
      }, []);

      
    return ( <div className="race-choose-tab">
    <div className="race-choose-app">
        <div className="race-choose-header">
            <span data-trans="choose race" className='race-choose-text'>Choose competion</span>

        </div>
        <div className="race-choose-races" id="races">
            {races.length == 0 ? <div className="loaderClock">
</div> : races.slice(0).reverse().map((rac:RaceAthleticsDTO,index) => (
                // <Race race={rac} key={rac.Id} onClick={onRaceidChanged} />
                <Race race={rac} key={index}  />
            ) )}
        </div>
    </div>
</div>);
}

export default Races
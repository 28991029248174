import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { RaceAthleticsDTO } from "../interfaces/RaceAthleticsDTO";
import {
  GrenDTO,
  GrenDetailDTO,
  MenuRoot,
} from "../interfaces/MenuAthleticsDTO";
import { EventDTO, StatusAthleticsDTO } from "../interfaces/StatusAthleticsDTO";
import { resultViewMode } from "../interfaces/enumSettings";
import {
  GetSignalRStatus,
  connection,
  startConnection,
} from "./signalrConnection";
import { ResultAthleticsDTO } from "../interfaces/ResultAthleticsDTO";
import apiService from "./apiService";
import {
  HubConnection,
  HubConnectionState,
} from "@microsoft/signalr/dist/esm/HubConnection";
import { eventBus } from "./eventHandler";
import { isNumberObject } from "util/types";
import { storageDTO } from "../interfaces/storageDTO";

interface RaceContextProps {
  raceid: number;
  setRaceid: (inp: number) => void;
  race: RaceAthleticsDTO | undefined;
  menus: MenuRoot | undefined;
  status: EventDTO[];
  setRace: (inp: RaceAthleticsDTO) => void;
  setMenus: (inp: MenuRoot) => void;
  setStatus: (inp: EventDTO[]) => void;
  getStatus: (raceno: string, round: string, heat: string) => EventDTO | null;
  getSelectedStatus: (menuKey: string | undefined) => EventDTO | null;
  viewMode: resultViewMode;
  setViewMode: (mode: resultViewMode) => void;
  selectedGren: GrenDTO | null;
  setSelctedGren: (inp: GrenDTO | null, menuKey: string) => void;
  resultList: ResultAthleticsDTO | null;
  loadResultList: (raceno: string, round: string, heat: string) => void;
  rollingClock: string;
  getActiveAthlete: (inp: GrenDetailDTO | undefined, startno: string) => number;
  menuOldSelected: GrenDTO[];
}
const RaceContext = createContext<RaceContextProps | undefined>(undefined);

type RaceProviderref = { children: ReactNode };

export const RaceProvider: React.FC<RaceProviderref> = ({ children }) => {
  const [lastGrenar, setLastGrenar] = useState<GrenDTO[]>([]);
  const [raceid, setRaceids] = useState(-1);
  const [race, setRace] = useState<RaceAthleticsDTO | undefined>(undefined);
  const [menus, setMenus] = useState<MenuRoot | undefined>();
  const [menuSelected, setMenuSelected] = useState<grenMenuKey>({});
  const [menuOldSelected, setMenuOldSelected] = useState<GrenDTO[]>([]);
  const [status, setStatus] = useState<EventDTO[]>([]);
  const [viewMode, setViewMode] = useState<resultViewMode>(
    resultViewMode.livemode
  );
  const [resultList, setResultList] = useState<ResultAthleticsDTO | null>(null);
  const [selectedGren, setPrivateSelctedGren] = useState<GrenDTO | null>(null);
  const [rollingClock, setRollingClock] = useState<string>("");

  const raceidRef = useRef(-1);
  const selectedGrenRef = useRef<GrenDTO | null>(null);
  const statusRef = useRef<EventDTO[]>([]);
  const conStat = useRef<signalR.HubConnectionState>(
    HubConnectionState.Disconnected
  );
  const setRaceid = (inp: number) => {
    setRaceids(inp);
  };
  useEffect(() => {
    if (!race) {
      return;
    }
    try {
      startConnection("raceid_" + race?.Id);
      conStat.current = GetSignalRStatus();
      // conStat.current =
      //  if (connection.state === HubConnectionState.Connected){
      //   connection.invoke("joinGroupAthletic", "raceid_" + race?.Id);
      //  }
      //  else{
      //   setTimeout(() => {
      //      if (connection.state === HubConnectionState.Connected){
      //       connection.invoke("joinGroupAthletic","aa" +  race?.Id);
      //       }
      //   }, 1050);
      //  }

      // console.log("connected to group");
    } catch {
      console.log("Couldnt join group");
    }
  }, [race]);

  // useEffect(() => {
  //   console.log("state connection changed " + connection ? connection.state : "null");
  // },[conStat])
  useEffect(() => {
    // connection.invoke("joinGroup", raceid);
    // try {

    const changedConnection = (stat: HubConnectionState) => {
      console.log("Connection changed", stat);
      if (conStat.current === null) {
        if (stat === HubConnectionState.Connected) {
          connectEvents();
        }
      } else if (stat !== HubConnectionState.Connected) {
        connectEvents();
      }
      conStat.current = stat;
    };

    eventBus.addListener(`signalR_con`, changedConnection);
    const connectEvents = () => {
      try {
        connection.on("setAthMenu", (message) => {
          let data: signalRMessage = JSON.parse(message);
          if (data.RaceID == raceidRef.current && data.DataMenu) {
            setMenus(data.DataMenu);
          }
        });
        connection.on("setCl", (message) => {
          // let data: signalRMessage = JSON.parse(message);
          // console.log(message,"aoaka");
          setRollingClock(message);
        });
        connection.on("setAthStatus", (message) => {
          console.log("Message received:", message);
          let data: signalRMessage = JSON.parse(message);
          console.log(data.RaceID, raceidRef.current, data.DataStatus);
          if (data.RaceID == raceidRef.current && data.DataStatus) {
            console.log("setting data ");
            setStatus(data.DataStatus.Events);
          }
        });
        connection.on("setAthResult", (message) => {
          console.log("Message received:", message);
          let data: signalRMessage = JSON.parse(message);
          if (
            data.RaceID == raceidRef.current &&
            data.DataResult &&
            selectedGrenRef.current
          ) {
            if (
              selectedGrenRef.current.Gren?.Raceno == data.Raceno &&
              selectedGrenRef.current.Gren?.Round == data.Round &&
              selectedGrenRef.current.Gren?.Heat == data.Heat
            ) {
              setResultList(data.DataResult);
            }
          }
        });
        connection.on("setActiveAthlete", (message) => {
          // console.log("Message received:", message);
          let data: signalRMessage = JSON.parse(message);
          if (data.RaceID == raceidRef.current && status) {
            let indexToUpdate = statusRef.current.findIndex(
              (o) =>
                o.Raceno == data.Raceno &&
                o.Round == data.Round &&
                o.Heat == data.Heat
            );
            if (indexToUpdate > -1) {
              // Make a copy of the current status array
              let updatedStatus = [...statusRef.current];

              // Update the specific element
              updatedStatus[indexToUpdate] = {
                ...updatedStatus[indexToUpdate],
                Startno: data.Startno,
              };
              // console.log(updatedStatus[indexToUpdate]);
              // Set the updated array as the new state
              statusRef.current = updatedStatus;
              setStatus(statusRef.current);
            }
          }
        });
      } catch {
        console.log("some error for wsockets");
      }
    };
    return () => {
      console.log("live socket destryed");
      eventBus.removeListener(`connectionSignalR`, changedConnection);
      try {
        connection.stop();
      } catch {
        console.log("live socket destryed error");
      }
    };
  }, []);
  const addLastMenuItem = (menu:GrenDTO) => {
    setMenuOldSelected(prev => {
      let newArr = [...prev];
      newArr.push(menu);
      if (newArr.length > 10){
        newArr = newArr.splice(10,newArr.length);
      }
      return newArr;
    })
  }

  useEffect(() => {
    raceidRef.current = raceid;
  }, [raceid]);

  const getStatus = (
    raceno: string,
    round: string,
    heat: string
  ): EventDTO | null => {
    let sta = status.filter(
      (o) => o.Raceno == raceno && o.Round == round && o.Heat == heat
    );
    if (sta.length > 0) {
      return sta[0];
    }
    return null;
  };
  const getSelectedStatus = (menuKey: string | undefined): EventDTO | null => {
    if (menuKey) {
      if (menuKey in menuSelected) {
        let men = menuSelected[menuKey];
        if (men.Gren){
          let sta = status.filter(
            (o) =>
              o.Raceno == men.Gren!.Raceno &&
              o.Round == men.Gren!.Round &&
              o.Heat == men.Gren!.Heat
          );
          if (sta.length > 0) {
            return sta[0];
          }
        }
      }
      return null;
    } else {
      if (!selectedGren) {
        return null;
      }
      if (!selectedGren.Gren) {
        return null;
      }

      let sta = status.filter(
        (o) =>
          o.Raceno == selectedGren.Gren!.Raceno &&
          o.Round == selectedGren.Gren!.Round &&
          o.Heat == selectedGren.Gren!.Heat
      );
      if (sta.length > 0) {
        return sta[0];
      }
    }
    return null;
  };
  const getActiveAthlete = (
    grens: GrenDetailDTO | undefined,
    startno: string
  ): number => {
    if (!grens) {
      return 0;
    }
    let strn: null | number = null;
    if (Number(startno)) {
      strn = parseInt(startno);
    }
    let da = statusRef.current.filter(
      (o) =>
        o.Raceno == grens.Raceno &&
        o.Round == grens.Round &&
        o.Heat == grens.Heat &&
        o.Startno == strn
    );
    if (da.length > 0) {
      return 1;
    }
    return 0;
  };

  const loadResultList = (raceno: string, round: string, heat: string) => {
    const fetchData = async () => {
      try {
        const result = await apiService.get<ResultAthleticsDTO>(
          `/GetResult/${raceid}?Raceno=${raceno}&Round=${round}&Heat=${heat}`
        );
        setResultList(result);

        // console.log(JSON.stringify(result));
      } catch (error) {
        setResultList(null);
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  };

  const setSelctedGren = (inp: GrenDTO | null, menuKey: string) => {
    // let filt = menus.filter()

    if (inp) {
      saveToLocalStorage(inp, menuKey);
      setPrivateSelctedGren(inp);
      // let v:grenMenuKey = [menuKey]: inp;
      addLastMenuItem(menuSelected);
      setMenuSelected((prevMenuSelected) => ({
        ...prevMenuSelected,
        [menuKey]: inp,
      }));
      addLastMenuItem(inp)
    }
  };
  const saveToLocalStorage = (inp: GrenDTO | null, menuKey: string) => {
    if (raceid < 1) {
      return;
    }
    let stor: storageDTO = {
      raceid: raceid,
      selectedgren: inp,
      menuKey: menuKey,
    };
    localStorage.setItem("race_" + raceid, JSON.stringify(stor));
  };
  useEffect(() => {
    selectedGrenRef.current = selectedGren;
  }, [selectedGren]);

  useEffect(() => {
    statusRef.current = status;
    // console.log(new Date(), status[11]);
  }, [status]);

  return (
    <RaceContext.Provider
      value={{
        raceid,
        setRaceid,
        race,
        setRace,
        menus,
        setMenus,
        status,
        setStatus,
        getStatus,
        getSelectedStatus,
        viewMode,
        setViewMode,
        setSelctedGren,
        selectedGren,
        loadResultList,
        resultList,
        rollingClock,
        getActiveAthlete,
        menuOldSelected
      }}
    >
      {children}
    </RaceContext.Provider>
  );
};

export const useRace = (): RaceContextProps => {
  const context = useContext(RaceContext);
  if (!context) {
    throw new Error("useRace must be used within a RaceProvider");
  }
  return context;
};

interface signalRMessage {
  RaceID: number;
  Raceno: string | undefined;
  Round: string | undefined;
  Heat: string | undefined;
  DataResult?: ResultAthleticsDTO | undefined;
  DataStatus?: StatusAthleticsDTO | undefined;
  DataMenu?: MenuRoot | undefined;
  Startno?: number | undefined;
}
interface grenMenuKey {
  [key: string]: GrenDTO;
}


import React from 'react'
import { HeightTableDTO, ResultsHeadDTO } from '../../../../../interfaces/ResultAthleticsDTO'
import ResultTableHeaderCommon from '../ResultTableHeaderCommon';
import { useRace } from '../../../../../services/RaceProvider';
import { CheckIfMultiRace } from '../../../../../services/checkMgren';

interface Props{
  header:ResultsHeadDTO;
  heightTable:HeightTableDTO | undefined;
}

const ResultTableHeaderHigh = ({header,heightTable}:Props) => {
  const {resultList} = useRace();
  if (!heightTable){
    return null;
  }
  const getHeight = (inp:number):string => {
    // console.log(inp,heightTable[`'Height${inp}'`],heightTable);
   return heightTable[`Height${inp}`];
  }
  return (
    <tr className='result-table-row'>
      <ResultTableHeaderCommon />
      {CheckIfMultiRace(resultList?.Title) ? (<th className="result-table-best" >Points</th>) : null}
      <th className="result-table-best" >Best</th>
      {Array.from({ length: parseInt(heightTable.Count) }, (_, i) => i + 1).map(
        (heightIndex) => (
          <th className="result-table-height" key={heightIndex} data-heightindex={heightIndex}>
           {getHeight(heightIndex)}
          </th>
        )
      )}
      </tr>
  )
}

export default ResultTableHeaderHigh
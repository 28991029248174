import React from 'react'
import { EventHeadsDTO } from '../../../../../interfaces/ResultAthleticsDTO';

interface Props {
    events: EventHeadsDTO | undefined;
  }

const ResultTableHeaderMulti = ({events}:Props) => {
  return (
    <tr className="result-table-row">
    <th className="result-table-rank">Pl.</th>
    <th className="result-table-bib">Strn.</th>
    <th className="result-table-name">Name</th>
    <th className="result-table-runtime">Tot</th>
    {events ? Object.keys(events).map((key,index) => {
      if (events[key] == ""){
        return null;
      }

      return (<th key={index} data-event={key}>{events[key]}</th>)
    }) : null}
  </tr>
  )
}

export default ResultTableHeaderMulti
import axios from 'axios';

//  export const apiURL:string = 'https://localhost:7021/api/AthleticsLive/';
export const apiURL:string = 'http://liveapi2.elitetiming.se/api/AthleticsLive/';

const instance = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
    // ... any other axios settings
});

export default instance;

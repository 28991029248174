import React from "react";
import Races from "./components/race/Races";
import Loading from "./components/loader/Loading";
import ResultPage from "./components/results/ResultPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RaceProvider } from "./services/RaceProvider";

const LiveApp = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Races />,
    },
    {
      path: "/loading",
      element: <Loading />,
    },
    {
      path: "/live/:id",
      element: <ResultPage />,
    },
    {
      path: "*",
      element: <Races />,
    },
  ]);
  return (
    <RaceProvider>
      <RouterProvider router={router} />
    </RaceProvider>
  );
};

export default LiveApp;

import React, { useEffect, useState } from 'react'
import { GrenDTO } from '../../../../interfaces/MenuAthleticsDTO'
import { EventDTO } from '../../../../interfaces/StatusAthleticsDTO';
import { useRace } from '../../../../services/RaceProvider';

interface Props{
    menu:GrenDTO;
    getMenuNameChanged: (inp:EventDTO  | null) => void;
}
export const formatMenu = (menuStatus:EventDTO | undefined | null):string | null =>{
  return menuStatus ? menuStatus.Racetext + " " + menuStatus.Heatcaption + " " +`${menuStatus?.Raceno}_${menuStatus?.Round}_${menuStatus?.Heat}` : null;
 };
const ResultMenu = ({menu,getMenuNameChanged}:Props) => {
  const {getStatus,status} = useRace();  
  const [menuStatus,setMenuStatus] = useState<EventDTO | null>();
 

  useEffect(() => {
    if (menu.Gren){
      setMenuStatus(getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat));
      
    }    
  },[])
  // useEffect(() => {
  //   if (menu.Gren){
  //     setMenuStatus(getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat));
      
  //   }
  //   console.log("get status har förändrats");
  // },[status])
 useEffect(() => {
  // getMenuNameChanged = () => (menuStatus);
  //  getMenuNameChanged(formatMenu());
   formatMenu(menuStatus);
 },[menuStatus])

 const formatTime = (inp:string | undefined | null):string | null => {
  if (!inp){
    return null;
  }
  try {
    const date = new Date(inp);

    // Get hours and minutes
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    // Format the time
    return  `(${hours}:${minutes})`;
    
  } catch (error) {
    
  }

  return null;
 };

//{`${menuStatus?.Raceno}_${menuStatus?.Round}_${menuStatus?.Heat}`}
  // console.log(menuStatus);
  if (!menu.Gren){
    return null;
  }
  

  return (
    <div data-statusas-type={getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat)?.Status}>
      {getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat)?.Racetext} {getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat)?.Heatcaption} {formatTime(getStatus(menu.Gren.Raceno,menu.Gren.Round,menu.Gren.Heat)?.Starttime)} 
    </div>
  )
}

export default ResultMenu
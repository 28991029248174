import React from "react";
import { ResultsHeadDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableHeaderCommon from "../ResultTableHeaderCommon";

interface Props {
  header: ResultsHeadDTO;
  relay?: boolean;
}
const ResultTableHeaderRelayRow = ({ header }: Props) => {
  return (
    <tr className="result-table-row">
      <th className="result-table-rank">Pl.</th>
      <th className="result-table-bib">Strn</th>
      <th className="result-table-name">Namn</th>
      <th className="result-table-nation">Nat</th>
      <th className="result-table-runtime">Runtime</th>
      <th className="result-table-code">comment</th>
    </tr>
  );
};

export default ResultTableHeaderRelayRow;

import React from "react";
import { ResultsHeadDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableHeaderCommon from "../ResultTableHeaderCommon";
import { CheckIfMultiRace } from "../../../../../services/checkMgren";
import { useRace } from "../../../../../services/RaceProvider";

interface Props {
  header: ResultsHeadDTO;
  relay?: boolean;
}
const ResultTableHeaderRunning = ({ header }: Props) => {
  const { resultList } = useRace();
  return (
    <tr className="result-table-row">
      {/* <td>Lane</td> */}
      <ResultTableHeaderCommon />
      {CheckIfMultiRace(resultList?.Title) ? (
        <td className="result-table-best">Points</td>
      ) : null}

      <th className="result-table-runtime">Runtime</th>
      <th className="result-table-code">comment</th>
    </tr>
  );
};

export default ResultTableHeaderRunning;

import React from "react";
import { ResultDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableCommonRow from "../ResultTableCommonRow";
import { GrenDetailDTO } from "../../../../../interfaces/MenuAthleticsDTO";
import { useRace } from "../../../../../services/RaceProvider";
interface Props {
  athlete: ResultDTO;
  grenDetails: GrenDetailDTO | undefined;
  relay?: boolean;
}
const ResultTableRelayRow = ({ athlete, grenDetails, relay }: Props) => {
  const { getActiveAthlete } = useRace();
  if (!athlete.Cid) {
    return null;
  }
  return (
    <>
    <tr
      className="result-table-row"
      data-athlete-active={getActiveAthlete(grenDetails, athlete.Stno)}
    >
      <td className="result-table-rank">{athlete.Rank}</td>
      <td className="result-table-bib">{athlete.Stno}</td>
      <td className="result-table-name">{athlete.Name}</td>
      <td className="result-table-nation">{athlete.Nat}</td>
      <td className="result-table-runtime">{athlete.ResTime_text}</td>
      <td className="result-table-code">{athlete.Codetext}</td>
    </tr>
    <tr className="result-table-row result-table-row-leg">
        <td colSpan={6} style={{width: "100%"}}>
            {athlete.Legs ? athlete.Legs.map((obj) => (<span className="result-table-leg"  key={obj.Id}> <b>{obj.Leg && obj.Leg + "."}</b> {obj.Athlete}</span>)) : null}
        </td>
    </tr>
    </>
  );
};

export default ResultTableRelayRow;

import React from 'react'
import { ResultDTO } from '../../../../../interfaces/ResultAthleticsDTO';
import ResultTableCommonRow from '../ResultTableCommonRow';
import { GrenDetailDTO } from '../../../../../interfaces/MenuAthleticsDTO';
import { useRace } from '../../../../../services/RaceProvider';
import { CheckIfMultiRace } from '../../../../../services/checkMgren';
interface Props {
    athlete: ResultDTO;
    grenDetails: GrenDetailDTO | undefined;
  }
const ResultTableThrowRow = ({athlete,grenDetails}:Props) => {
  const {getActiveAthlete,resultList} = useRace();
  
  // console.log(athlete.AttemptResults);
  
    if (!athlete.Name)
    {
        return null;
    }
    else if (!athlete.AttemptResults){
      return (<tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
      <ResultTableCommonRow athlete={athlete} />
      {CheckIfMultiRace(resultList?.Title) ? (<td className="result-table-best">{athlete.T6_res}</td>) : null}
      <td className='result-table-code'>{athlete.Codetext}</td>
      <td className='result-table-best'>{athlete.Bestres}</td>
      <td className='result-table-attempt'>&nbsp;</td>
      <td className='result-table-attempt'>&nbsp;</td>
      <td className='result-table-attempt'>&nbsp;</td>
      <td className='result-table-attempt'>&nbsp;</td>
      <td className='result-table-attempt'>&nbsp;</td>
      <td className='result-table-attempt'>&nbsp;</td>
      
      </tr>)
    }
  return (
    <tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
    <ResultTableCommonRow athlete={athlete} />
    <td className='result-table-code'>{athlete.Codetext}</td>
    <td className='result-table-best'>{athlete.Bestres}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp1?.Res}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp2?.Res}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp3?.Res}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp4?.Res}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp5?.Res}</td>
    <td className='result-table-attempt'>{athlete.AttemptResults.Attemp6?.Res}</td>
    
    </tr>
  )
}

export default ResultTableThrowRow
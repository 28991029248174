import React from 'react'
import { TitleDTO } from '../../../interfaces/ResultAthleticsDTO';
import { useRace } from '../../../services/RaceProvider';

interface Props {
    title: TitleDTO;
}

const ResultTitle = ({title}:Props) => {
  const {getSelectedStatus} = useRace();
  return (
    <div className='result-title' data-status-type={getSelectedStatus(undefined) ? getSelectedStatus(undefined)?.Status : null}>
        <span className='result-title-list'>{title.List_Name}:</span>
        <span className='result-title-event'>{title.Event_Name}</span>
        <span className='result-title-caption'>{title.Heat_Caption}</span>
        <span className='result-title-list'> {title.Racetype}</span>
    </div>
  )
}

export default ResultTitle
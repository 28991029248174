import { EventEmitter } from 'events';




const GetEmitter = ():EventEmitter => {
    let evmit = new EventEmitter();
    evmit.setMaxListeners(0);
    return evmit;
}
export const eventBus:EventEmitter = GetEmitter();
import React from "react";
import { ResultDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableCommonRow from "../ResultTableCommonRow";
import { GrenDetailDTO } from "../../../../../interfaces/MenuAthleticsDTO";
import { useRace } from "../../../../../services/RaceProvider";
import { CheckIfMultiRace } from "../../../../../services/checkMgren";
interface Props {
  athlete: ResultDTO;
  grenDetails: GrenDetailDTO | undefined ;
}
const ResultTableJumpRow = ({ athlete,grenDetails }: Props) => {
  const {getActiveAthlete,resultList} = useRace();
  if (!athlete.Name) {
    return null;
  }
  if (!athlete.AttemptResults || !athlete.AttemptResults.Attemp1 || !athlete.AttemptResults.Attemp2 || !athlete.AttemptResults.Attemp3 || !athlete.AttemptResults.Attemp4 || !athlete.AttemptResults.Attemp5 || !athlete.AttemptResults.Attemp6) {
    // console.log("AttemptResults or Attemp1 is undefined");
    return     (<tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
      <ResultTableCommonRow athlete={athlete} />
      <td className="result-table-code">{athlete.Codetext}</td>
      <td className="result-table-best">{athlete.Bestres}</td> 
      
      </tr>);
  }

  return (
    <tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
      <ResultTableCommonRow athlete={athlete} />
      <td className="result-table-code">{athlete.Codetext}</td>
      {CheckIfMultiRace(resultList?.Title) ? (<td className="result-table-best">{athlete.T6_res}</td>) : null}
      <td className="result-table-best">{athlete.Bestres}</td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp1?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp1?.Wind}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp2?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp2?.Wind}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp3?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp3?.Wind}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp4?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp4?.Wind}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp5?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp5?.Wind}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp6?.Res}
      </td>
      <td className="result-table-attempt">
        {athlete.AttemptResults.Attemp6?.Wind}
      </td>
    </tr>
  );
};

export default ResultTableJumpRow;

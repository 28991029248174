import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  GrenDTO,
  MenuAthleticsDTO,
} from "../../../../interfaces/MenuAthleticsDTO";
import ResultMenu, { formatMenu } from "./ResultMenu";
import Dropdown from "react-bootstrap/Dropdown";
import { useRace } from "../../../../services/RaceProvider";
import { EventDTO } from "../../../../interfaces/StatusAthleticsDTO";

interface Props {
  menu: MenuAthleticsDTO;
  menuKey: string;
}

const ResultMenuGroup = ({ menu,menuKey }: Props) => {
  const { getStatus, setSelctedGren,status,getSelectedStatus ,raceid} = useRace();
  const [selectedHeat, setSelectedHeat] = useState<EventDTO | null>(null);
  const [selectedHeatText, setSelectedHeatText] = useState<string | null>(null);
  const handleClick = (gre: GrenDTO) => {
    
    // if (gre.Gren) {
    //   setSelectedHeat(
    //     getStatus(gre.Gren.Raceno, gre.Gren.Round, gre.Gren.Heat)
    //   );
    // } else {
    //   setSelectedHeat(null);
    // }
    setSelctedGren(gre,menuKey);
  };
  useEffect(() => {
    setSelectedHeatText(formatMenu(selectedHeat));
  },[selectedHeat,status])
  const handleChange = (inp:EventDTO | null | undefined) => {
  }
  
  return (
    <div className="result-box-menu-group" data-menu-key={menuKey}>
      <span className="result-box-menu-group-text">{menu.Rubriker.Rubrik}</span>
      <div className="result-box-menu-group-drop">
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="btn-sm result-box-menu-group-dropdown"
            key={menu.Rubriker.Rubrik}
          >
            {getSelectedStatus(menuKey)
              ? getSelectedStatus(menuKey)?.Racetext + " " + getSelectedStatus(menuKey)?.Heatcaption
              : "Välj heat"}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ maxHeight: "300px", overflowY: "auto" }}>
            {menu.Grenar.map((obj, index) => {
              if (!obj.Gren || !raceid){
                return null;
              }
              
              let stat = getStatus(obj.Gren.Raceno,obj.Gren.Round,obj.Gren.Heat); //?.Status
              if (!stat){
                return null;
              }
              else if (parseInt(stat.Raceno.toString()) >= 200 && raceid === 11){
                return null;
              }
              else if (parseInt(stat.Raceno.toString()) < 200 && raceid === 12){
                return null;
              }
              else if (stat.Status < 20 || stat.Status > 80){
                return null;
              }
              return (
                <>
                 <Dropdown.Item 
  style={{ 
    order: (() => {
      const status = getStatus(obj.Gren.Raceno, obj.Gren.Round, obj.Gren.Heat)?.Status;
      if (status === undefined) {
        return 0; // default order value if status is undefined
      }
      if (status === 40) {
        return 100;
      }
      if (status === 20) {
        return status + 300;
      }
      if (status === 10) {
        return status + 400;
      }
      if (status === 30) {
        return status + 200;
      }
      return Math.abs(status);
    })()
  }} 
  key={menu.Rubriker.Rubrik + "_" + index} 
  onClick={() => handleClick(obj)} 
  className={"status-type-" + getStatus(obj.Gren.Raceno, obj.Gren.Round, obj.Gren.Heat)?.Status + " result-box-drop-down"}
>
  <ResultMenu menu={obj} getMenuNameChanged={handleChange} />
</Dropdown.Item>


                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ResultMenuGroup;

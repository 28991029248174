import React from "react";
import { EventHeadsDTO, ResultDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import { GrenDetailDTO } from "../../../../../interfaces/MenuAthleticsDTO";

interface Props {
  athlete: ResultDTO;
  grenDetails: GrenDetailDTO | undefined | null;
}
const ResultTableTeam = ({ athlete,grenDetails }: Props) => {

  const getFormRes = (inp:string):string | null => {
    if (inp === ""){
      return null;
    }
    else if (inp === "0"){
      return "0";
    }
    else{
      return inp;
    }
  }

  return (
    <tr className="result-table-row">
      <td className="result-table-rank">{athlete.Rank}</td>
      <td className="result-table-name">{athlete.Team_Name}</td>
      <td className="result-table-runtime">{athlete.Tot}</td>
      { athlete.Eventresults && !Array.isArray(athlete.Eventresults) ? 
        (Object.keys(athlete.Eventresults as EventHeadsDTO).map((key, index) => {
            // Ensure getFormRes can handle the type of value being passed
            // Now we assert that athlete.Eventresults is of type EventHeadsDTO
            const resultValue = (athlete.Eventresults as EventHeadsDTO)[key];
            return <td key={index} data-event={key}>{getFormRes(resultValue)}</td>;
        })) 
        : null
    }
    </tr>
  );
};

export default ResultTableTeam;

import moment from "moment";
import React, { useEffect, useState } from "react";


const Clock = () => {
  const [timeOfDay, setTimeOfDay] = useState("..:,,:..");
  const [timediff, setTimediff] = useState<number>(0);

  useEffect(() => {
    const handler = (diffTime: number) => {
      // setAthletes(ath);+
      setTimediff(diffTime);
    };
    const intervalId = setInterval(() => {
      let tim = moment().format("HH:mm:ss");
      setTimeOfDay(tim);
    }, 250);

    return () => {
      // Cleanup to avoid memory leaks
      clearInterval(intervalId);
    };
  }, []);

  return <>{timeOfDay}</>;
};

export default Clock;

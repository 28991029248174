import React from 'react'
import ResultMenu from './resultmenu/ResultMenu'
import ResultMenus from './resultmenu/ResultMenus'
import Result from './Result'
import { useRace } from '../../../services/RaceProvider'

const ResultBox = () => {
  const {menuOldSelected,menus} = useRace();
  return (
    <div className='result-box'>
      {/* <div className='result-box-last-menu'>
        aa
        {menuOldSelected && menus ? menuOldSelected.map((obj) => 
        {
        return (<span>{obj.Gren?.Heat}</span>)
      }
      ) : null}
      </div> */}
      <div className='result-box-menu'>
        <ResultMenus />
      </div>
      <div className='result-box-content'>
        <Result />
      </div>
    </div>
  )
}

export default ResultBox
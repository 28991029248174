import { TitleDTO } from "../interfaces/ResultAthleticsDTO";

export const  CheckIfMultiRace = (inp:TitleDTO | null | undefined):boolean =>{
    
    if (!inp){
        return false;
    }
    if (inp.Mgren && isNumber(inp.Mgren)){
        const mGrenV = parseInt(inp.Mgren);
        if (mGrenV > 0){
            return true;
        }
    }
    return false;
}

function isNumber(value?: string | number): boolean
{
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
}

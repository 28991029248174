import React, { useEffect, useState } from 'react'
import "./styles/footer.css";
import "./styles/header.css";
import "./styles/raceapp.css";
import "./styles/resultstyle.css";
import ResultHeader from './ResultHeader';
import ResultFooter from './ResultFooter';
import { useParams } from 'react-router-dom';
import { useRace } from '../../services/RaceProvider';
import apiService from '../../services/apiService';
import { TotalRaceDTO } from '../../interfaces/TotalRaceDTO';
import ResultBox from './resultviews/ResultBox';
import Loading from '../loader/Loading';
import { storageDTO } from '../../interfaces/storageDTO';
import { GrenDTO, GrenDetailDTO } from '../../interfaces/MenuAthleticsDTO';
import { EventDTO, StatusAthleticsDTO } from '../../interfaces/StatusAthleticsDTO';

const ResultPage = () => {
  const [showLoading,setShowLoading] = useState<boolean>(true);
  const {raceid,setRaceid,setRace,setMenus,setStatus,setSelctedGren} = useRace();
  const { id } = useParams();
  useEffect( () => {
    const raceStorageData = localStorage.getItem("race_" + id);
    let interval:NodeJS.Timer | null = null; 
    const fetchData = async () => {
      try {
        const result = await apiService.get<TotalRaceDTO>(
          "/GetRace/" + id
        );
        setRaceid(parseInt(id!));
          setRace(result.Race);
          setMenus(result.Menus);
          setStatus(result.HeatStatus.Events);

        // console.log(JSON.stringify(result));
        // setRaces(result);
        document.title = 'CS-Elite: ' + result.Race.Name;
        setShowLoading(false);
        if (raceStorageData){
          let rData:storageDTO | null | undefined = JSON.parse(raceStorageData);
          if (rData && rData.selectedgren){
            setSelctedGren(rData.selectedgren,rData.menuKey);
          }
        }
        interval = setInterval(() => {
          // readResult();  
          // console.log("read status" + (new Date()))
          if (id){
            downloadStatus(id);
          }
         
         }, 25000); // 1000 milliseconds interval, you can adjust this value as needed
       

      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
    return () => {
      if (interval){
        clearInterval(interval);
      }
    }; // This will clear the interval when the component unmounts

  },[]);

  const downloadStatus = (ids:string) => {
    const fetchData = async () => {
      try {
        const result = await apiService.get<StatusAthleticsDTO>(
          `/GetStatus/${ids}`
        );
        // setResultList(result);
          if (result){
            // console.log(result);
            setStatus(result.Events);
          }
         
        // console.log(JSON.stringify(result));
      } catch (error) {
        // setResultList(null);
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }
  if (showLoading){
    return (<Loading />)
  }

  return (
    <>
    <div className="result-header">
        <ResultHeader          />
      </div>
      <div className="race-container">
        <div className="race-app">
          <div className="race-app-inner">
              <ResultBox />
          </div>
        </div>
      </div>
      <div className="result-footer">
        <ResultFooter  />
      </div>
    </>
  )
}

export default ResultPage
import React from 'react'
import { useRace } from '../../services/RaceProvider'

const ResultHeader = () => {
    const {race} = useRace();
    

  return (<>
    <div className="result-header-loggo">&nbsp;</div>
    <div className="result-header-menu">
    
   
    </div>
    <div className="result-header-text">
      <span id="event-name" className="result-header-event-name">
        {race ? race?.Name : ""}
      </span>
      <span id="event-place" className="result-header-event-place">
        {race?.Place}
      </span>
    </div>
    <div className="result-header-settings">
      <div className="result-header-settings-button" id="menu-settings">
        &nbsp;
      </div>
    </div>
    </>
  )
}

export default ResultHeader
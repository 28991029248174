import React from 'react'
import { useRace } from '../../../../services/RaceProvider'
import ResultMenuGroup from './ResultMenuGroup';

const ResultMenus = () => {
  const {getStatus,menus} = useRace();
  return (
    <>
    {menus ?  Object.keys(menus).map((key,index) => {
     
      return (<ResultMenuGroup key={index} menu={menus[key]} menuKey={key} />);
    }) : "error"}
    </>
  )
}

export default ResultMenus
import React, { useEffect, useState } from "react";
import { useRace } from "../../../services/RaceProvider";
import apiService from "../../../services/apiService";
import {
  ResultAthleticsDTO,
  ResultDTO,
  ResultsHeadDTO,
} from "../../../interfaces/ResultAthleticsDTO";
import ResultTitle from "./ResultTitle";
import ResultTableHighRow from "./resulttables/resultable_four/ResultTableHighRow";
import "../styles/resulttable.css";
import ResultTableRunningRow from "./resulttables/resulttable_one/ResultTableRunningRow";
import ResultTableHeaderHigh from "./resulttables/resultable_four/ResultTableHeaderHigh";
import ResultTableHeaderRunning from "./resulttables/resulttable_one/ResultTableHeaderRunning";
import { GrenDetailDTO } from "../../../interfaces/MenuAthleticsDTO";
import ResultTableThrowRow from "./resulttables/resulttable_second/ResultTableThrowRow";
import ResultTableHeaderThrow from "./resulttables/resulttable_second/ResultTableHeaderThrow";
import ResultTableJumpRow from "./resulttables/resulttable_third/ResultTableJumpRow";
import ResultTableHeaderJump from "./resulttables/resulttable_third/ResultTableHeaderJump";
import RollingClock from "./RollingClock";
import ResultTableHeaderTeam from "./resulttables/resulttable_seven/ResultTableHeaderTeam";
import ResultTableTeam from "./resulttables/resulttable_seven/ResultTableTeam";
import ResultTableHeaderMulti from "./resulttables/resulttable_six/ResultTableHeaderMulti";
import ResultTableMulti from "./resulttables/resulttable_six/ResultTableMulti";
import ResultTableRelayRow from "./resulttables/resulttable_five/ResultTableRelayRow";
import ResultTableHeaderRelayRow from "./resulttables/resulttable_five/ResultTableHeaderRelayRow";

interface Props {
  gren?:GrenDetailDTO | undefined;
}

const Result = ({gren}:Props) => {
  const { raceid, selectedGren,loadResultList,resultList } = useRace();
  // const [results, setResults] = useState<ResultAthleticsDTO | null>(null);

  

  useEffect(() => {
    // if (selectedGren?.Gren?.Raceno && selectedGren?.Gren?.Round && selectedGren?.Gren?.Heat){
    //   loadResultList(selectedGren?.Gren?.Raceno,selectedGren?.Gren?.Round,selectedGren?.Gren?.Heat);
    // }
    // else{
    //   // setResults(null);
    // }

    const readResult = () =>{
      if (selectedGren?.Gren?.Raceno && selectedGren?.Gren?.Round && selectedGren?.Gren?.Heat){
        loadResultList(selectedGren?.Gren?.Raceno, selectedGren?.Gren?.Round, selectedGren?.Gren?.Heat);
        // console.log("update " + (new Date));
      }
    };
    const interval = setInterval(() => {
     readResult();  
    //  console.log("read ing" + (new Date()))

    }, 5000); // 1000 milliseconds interval, you can adjust this value as needed
  
    readResult();

    return () => clearInterval(interval); // This will clear the interval when the component unmounts
  
    
  }, [selectedGren,gren]);

  const getRow = (
    inp: ResultDTO,
    type: string
  ): JSX.Element | null => {
    try 
    {
      if (type == "4") {
        return (
          <ResultTableHighRow athlete={inp} heightTable={resultList?.HeightTable} key={inp.Stno + "_high"} grenDetails={selectedGren?.Gren}  />
        );
      } else if (type == "1") {
        return <ResultTableRunningRow athlete={inp} key={inp.Stno + "_run"} grenDetails={selectedGren?.Gren}  />;
      }
      else if (type == "2") {
        return <ResultTableThrowRow athlete={inp} key={inp.Stno + "_throw"} grenDetails={selectedGren?.Gren}  />;
      }
      else if (type == "3") {
        return <ResultTableJumpRow athlete={inp} key={inp.Stno + "_jump"} grenDetails={selectedGren?.Gren}  />;
      }
      else if (type == "7") {
        return <ResultTableTeam athlete={inp} key={inp.Stno + "_team"} grenDetails={selectedGren?.Gren} />;
      }
      else if (type == "6") {
        return <ResultTableMulti athlete={inp} key={inp.Stno + "_multi"} grenDetails={selectedGren?.Gren} />;
      }
      else if (type == "5") {
        return <ResultTableRelayRow athlete={inp} key={inp.Stno + "_run"} grenDetails={selectedGren?.Gren}  />;

      }
    }
    catch{

    }
   
    return null;
  };
  const getHeader = (inp:ResultsHeadDTO,type:string): JSX.Element | null => {
    if (type == "4"){
      return (<ResultTableHeaderHigh header={inp} heightTable={resultList?.HeightTable} key={"headerhigh"}/>)
    }
    else if (type == "1"){
      return (<ResultTableHeaderRunning header={inp} key={"headerrun"} />)
    }
    else if (type == "2"){
      return (<ResultTableHeaderThrow header={inp} key={"headerthrow"} />)
    }
    else if (type == "3"){
      return (<ResultTableHeaderJump header={inp} key={"headerjump"} />)
    }
    else if (type == "7"){
      return (<ResultTableHeaderTeam events={resultList?.EventHeads} key={"headerteam"} />)
    }
    else if (type == "6"){
      return (<ResultTableHeaderMulti events={resultList?.ResultsHead?.EventHeads} key={"headermulti"} />)
    }
    else if (type == "5"){
      return (<ResultTableHeaderRelayRow header={inp} key={"headermulti"} />)
    }
    return null;
  };

  if (!resultList) {
    return <span>Results not aviable</span>;
  }
  return (
    <div>
      <RollingClock />
      <ResultTitle title={resultList.Title} key={"titles"} />
      <table className="result-table">
        <thead className="result-table-header">{getHeader(resultList.ResultsHead,resultList.Title.Racetype)}</thead>
        <tbody>
        {resultList.Results.map((obj, index) => {
          return (
          <>
              {getRow(obj, resultList.Title.Racetype)}
            </>
          );
        })}
        </tbody>
      </table>
    </div>
  );
};

export default Result;

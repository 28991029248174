import React from 'react'
import { ResultDTO } from '../../../../interfaces/ResultAthleticsDTO';
import { useRace } from '../../../../services/RaceProvider';

interface Props{
  athlete:ResultDTO;
  relay?: boolean;
}

const ResultTableCommonRow = ({athlete,relay}:Props) => {
  // {athlete.Lane.length > 0 ? "-" + athlete.Lane : ""}
  const {raceid} = useRace();

  const formatBirth = (inp:string):string => {
    if(inp){
      return inp.split("-")[0];
    }
    return "";
  }

  return (
    <>
    <td className='result-table-rank'>{athlete.Rank}</td>
    <td className='result-table-bib'>{athlete.Stno_Lane ? athlete.Stno_Lane : athlete.Stno}</td>
    <td className='result-table-name'>{athlete.Name}</td>
    {relay ?( <td className='result-table-birth'>{formatBirth(athlete.Yearofbirth)}</td>) : null}
    {relay ?(<td className='result-table-club'>{athlete.Clubname}</td>) : null }
    <td className='result-table-nation'>{athlete.Clubname == "" ? athlete.Nat : athlete.Clubname}</td>
    </>
  )
}

export default ResultTableCommonRow
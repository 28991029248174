import React from 'react'
import Clock from '../../tools/Clock'

const ResultFooter = () => {
  return (
    <>
      <div className="result-footer-info">
        <span>© EliteTiming of Sweden AB</span>
        <span>® Alfred Andersson</span>
      </div>
      <div className="result-footer-center">        
      </div>
      <div className="result-footer-clock">
        <span className="result-footer-clock-time">
         <Clock />
        </span>
        <br></br>
        <span className="result-footer-clock-text">EliteTiming</span>
      </div>
    </>
  )
}

export default ResultFooter
import * as signalR from "@microsoft/signalr";
import { eventBus } from "./eventHandler";

export let  connection:signalR.HubConnection;

function createConnection() {
    connection  = new signalR.HubConnectionBuilder()
        .withUrl("http://liveapi2.elitetiming.se/myhubathletic") // Replace with your SignalR server URL
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect([0, 20000]) // Will reconnect immediately, then after 2, 10, and 30 seconds
        .build();

           
    connection.onreconnecting(error => {
        console.error(`SignalR connection reconnecting due to ${error}.`);
        eventBus.emit(`signalR_con`, connection.state);
        // You can update the application state here
    });

    connection.onreconnected(connectionId => {
        console.log(`SignalR connection reestablished. Connected with connectionId "${connectionId}".`);
        eventBus.emit(`signalR_con`, connection.state);
        // Rejoin groups or perform other necessary actions here
    });

    connection.onclose(error => {
        console.error(`SignalR connection closed due to ${error}.`);
        eventBus.emit(`signalR_con`, connection.state);
        // You can update the application state here
    });
    

    return connection;
}

export function startConnection(group:string) {
    // createConnection().start()
    //     .then(() => {
    //         console.log("SignalR connected");

    //         setTimeout(() => {
    //             if (connection.state === signalR.HubConnectionState.Connected){
    //                 connection.invoke("joinGroupAthletic",group);
    //             }
    //             else{
    //                 console.log("Couldn't join group");
    //             }
    //             eventBus.emit(`signalR_con`, connection.state);
    //         }, (150));
            
           
    //         // Join groups or perform initial setup here
    //     })
    //     .catch(err => {
    //         console.error("Error while establishing connection: ", err);
    //     });
}


export function GetSignalRStatus():signalR.HubConnectionState{
    return signalR.HubConnectionState.Connected;
     // connection.state;
}


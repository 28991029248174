import React from 'react'
import { useRace } from '../../../services/RaceProvider'

const RollingClock = () => {
    const {rollingClock} = useRace();
  return (
    <div><span>{rollingClock}</span></div>
  )
}

export default RollingClock
import React from "react";
import { ResultsHeadDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableHeaderCommon from "../ResultTableHeaderCommon";
import { useRace } from "../../../../../services/RaceProvider";
import { CheckIfMultiRace } from "../../../../../services/checkMgren";

interface Props {
  header: ResultsHeadDTO;
}
const ResultTableHeaderThrow = ({header}:Props) => {
  const {resultList} = useRace();
  return (
    <tr className="result-table-row">
      <ResultTableHeaderCommon />
      <th className="result-table-code">Code</th>
      {CheckIfMultiRace(resultList?.Title) ? (<td className="result-table-best">Points</td>) : null}

      <th className="esult-table-best">Best</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp1.Res}</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp2.Res}</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp3.Res}</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp4.Res}</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp5.Res}</th>
      <th className="esult-table-attempt">{header.AttemptResults?.Attemp6.Res}</th>
    </tr>
  );
};

export default ResultTableHeaderThrow;

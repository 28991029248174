import React from "react";
import "./loadingstyle.css"
const Loading = () => {

    return (<div className="loader">
    <div className="loaderinner">

        <div className="loaderLoggo">&nbsp;</div>
        <div className="loaderClock">

        </div>
        <div className="loaderText">Loading...</div>
    </div>
</div>);
}
export default Loading;
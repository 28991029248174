import React from "react";
import { ResultDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableCommonRow from "../ResultTableCommonRow";
import { GrenDetailDTO } from "../../../../../interfaces/MenuAthleticsDTO";
import { useRace } from "../../../../../services/RaceProvider";
import { CheckIfMultiRace } from "../../../../../services/checkMgren";
interface Props {
  athlete: ResultDTO;
  grenDetails: GrenDetailDTO | undefined ;
  relay?: boolean;
}
const ResultTableRunningRow = ({ athlete,grenDetails,relay }: Props) => {
  const {getActiveAthlete,resultList} = useRace();
  if (!athlete.Cid) {
    return null;
  }
// if (athlete.Lane && athlete.Lane != "0" && athlete.Rank == "0")
//  {
//   athlete.Stno_Lane =athlete.Lane + "-" + athlete.Stno ;
//  } 
  return (
    <tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
      {/* {athlete.Lane ? (<td>{athlete.Lane} </td>) : null} */}
      <ResultTableCommonRow relay={relay} athlete={athlete} />
      {CheckIfMultiRace(resultList?.Title) ? (<td className="result-table-best">{athlete.T6_res}</td>) : null}
      <td className="result-table-runtime">{athlete.ResTime}</td>
      <td className="result-table-code">{athlete.Codetext}</td>
    </tr>
  );
};

export default ResultTableRunningRow;

export enum resultViewMode{
    livemode = 1,
    resultmode = 11,

}
export enum sportType{
    running = 1,
    jump_throw_nowind = 2,
    jump = 3,
    high_pole = 4
}
import moment from 'moment';
import React from 'react'
import { RaceAthleticsDTO } from '../../interfaces/RaceAthleticsDTO';
interface Props{
    race:RaceAthleticsDTO
}

export const Race = ({race}:Props) => {
    console.log(race);
    const isToday = (): boolean => {
        if (
          moment(race.StartTime).format("yyyy-MM-DD") ==
          moment().format("yyyy-MM-DD")
        ) {
          return true;
        }
        return false;
      };
      return (
        <a
          href={"/live/" + race.Id}
          className="race-choose-row"
          data-raceid={race.Id}
          data-racedate={moment(race.StartTime).format("yyyy-MM-DD")}
        >
          <div
            className="race-choose-sport"
            data-sport="athletics"
            style={{ background: isToday() ? "green" : "" }}
          >
            {isToday() ? <span style={{ color: "white",
        verticalAlign: "revert-layer",
        transform: "translateY(15px)",
        display: "block",
        marginLeft: "3px",
        fontWeight: "600" }}>LIVE</span> : <>&nbsp;</>}
          </div>
          <div className="race-choose-info">
            <div className="race-choose-main-info">
              <span data-id="race-choose-name">{race.Name}</span>
            </div>
            <div className="race-choose-second-info">
              <span data-id="race-choose-date">{race.StartTime === null ? null :  moment(race.StartTime).format("yyyy-MM-DD")}</span>
              <span data-id="race-choose-place">{race.Place}</span>
            </div>
          </div>
        </a>
      );
}

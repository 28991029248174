import React from "react";
import { HeightTableDTO, ResultDTO } from "../../../../../interfaces/ResultAthleticsDTO";
import ResultTableCommonRow from "../ResultTableCommonRow";
import { GrenDetailDTO } from "../../../../../interfaces/MenuAthleticsDTO";
import { useRace } from "../../../../../services/RaceProvider";
import { CheckIfMultiRace } from "../../../../../services/checkMgren";

interface Props {
  athlete: ResultDTO;
  heightTable:HeightTableDTO | undefined;
  grenDetails: GrenDetailDTO | undefined;
}

const ResultTableHighRow = ({ athlete, heightTable,grenDetails }: Props) => {
  // console.log(athlete.HightposRess ? athlete.HightposRess['Height1'] : "Finsinte");
  const {getActiveAthlete,resultList} = useRace();
  // if (!athlete.HightposRess ){
  //   return null;
  // }
  
  return (
    <tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
      <ResultTableCommonRow athlete={athlete} />
      {CheckIfMultiRace(resultList?.Title) ? (<td className="result-table-best">{athlete.T6_res}</td>) : null}
      <td className="result-table-best">{athlete.Bestres}</td>
       {!heightTable || !athlete.HightposRess ? null :  Array.from({ length: parseInt(heightTable.Count) }, (_, i) => i + 1).map(
        (heightIndex) => (
          <td className="result-table-height" key={heightIndex} data-heightindex={heightIndex}>
            {athlete.HightposRess ? athlete.HightposRess[`Height${heightIndex}`] : "TOMT"}
          </td>
        )
      )}
    </tr>
  );
};

export default ResultTableHighRow;

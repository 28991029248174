import React from "react";
import ResultTableHeaderCommon from "../ResultTableHeaderCommon";
import { EventHeadsDTO } from "../../../../../interfaces/ResultAthleticsDTO";

interface Props {
  events: EventHeadsDTO | undefined;
}

const ResultTableHeaderTeam = ({events}:Props) => {
  return (
    <tr className="result-table-row">
      <th className="result-table-rank">Pl.</th>
      <th className="result-table-name">Team namn</th>
      <th className="result-table-runtime">Tot</th>
      {events ? Object.keys(events).map((key,index) => {
        return (<th key={index} data-event={key}>{events[key]}</th>)
      }) : null}
    </tr>
  );
};

export default ResultTableHeaderTeam;

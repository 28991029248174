import React from 'react'
import { ResultDTO } from '../../../../../interfaces/ResultAthleticsDTO';
import { GrenDetailDTO } from '../../../../../interfaces/MenuAthleticsDTO';
import { useRace } from '../../../../../services/RaceProvider';


interface Props {
    athlete: ResultDTO;
    grenDetails: GrenDetailDTO | undefined;
  }
const ResultTableMulti = ({ athlete,grenDetails }: Props) => {
    const {getActiveAthlete} = useRace();
    return (
        <tr className="result-table-row" data-athlete-active={getActiveAthlete(grenDetails,athlete.Stno)}>
          <td className="result-table-rank">{athlete.Rank}</td>
          <td className="result-table-bib">{athlete.Stno}</td>
          <td className="result-table-name">{athlete.Name}</td>
          <td className="result-table-runtime">{athlete.Tot}</td>
          { athlete.Eventresults && Array.isArray(athlete.Eventresults) ? 
            (athlete.Eventresults.map((obj, index) => {
                let key:string = "Bres" + (index + 1);
                const result = (obj as any)[key];
                if (result !== undefined){
                    if (result == ""){
                        return null;
                    }
                    return <td key={index} data-event={obj.Point}>{obj.Point}</td>;
                }
                else{
                    return null;
                }
               
            })) 
            : null
        }
        </tr>
      );
}

export default ResultTableMulti